<template lang="pug">
.box-select-item(@click="$emit('click', value)")
  img(v-if="image" :src="image")
  .box-select-item-title {{ $te(title) ? $t(title) : title }}
  .box-select-item-description(v-if="description") {{ $te(description) ? $t(description) : description }}
</template>

<script>
  export default {
    props: {
      title: { type: String, required: true },
      value: { type: String, required: true },
      image: { type: String },
      description: { type: String },
    },
  };
</script>

<style lang="sass">
  .box-select-item
    cursor: pointer
    width: 340px
    display: flex
    padding: 20px 0px 32px 0px
    flex-direction: column
    align-items: center
    gap: 8px
    flex: 1 0 0
    border-radius: 8px
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04), 0px 4px 10px 0px rgba(0, 0, 0, 0.10)
    @media (hover: hover) and (pointer: fine)
      &:hover
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)
    &-title
      font-size: 24px
      font-weight: 700
      line-height: 32px
      margin-top: 8px
      margin-bottom: 16px
    &-description
      font-size: 16px
      font-weight: 400
      line-height: 24px
</style>
